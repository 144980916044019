Promise.resolve();//dummy call




import { AutomatitCarousel } from './automatit_carousel.m.js';



//////////////////
//> Contact Form
//////////////////
var subIP = false;
if (document.getElementById('contact_form')) {
	document.getElementById('contact_form').addEventListener('submit', function (e) {
		if (document.getElementById('hp').value != '') return false;

		Array.prototype.forEach.call(document.querySelectorAll('#contact_form .error'), function (error) {
			error.classList.remove('error')
		});
		document.querySelector('#contact_form .response_message').innerHTML = '';

		if (subIP) {
			return;
		}

		var checkFields = new Array('name', 'email', 'message');
		var errorsFound = new Array();
		checkFields.forEach(function (item) {
			if (document.getElementById(item + '_input').value == '') {
				document.getElementById(item + '_input').classList.add('error');
				errorsFound.push(item);
			}
		});
		if (!subIP && errorsFound.length == 0) {
			subIP = true;
			var request = new XMLHttpRequest(),
				method = 'POST',
				url = document.getElementById('contact_form').getAttribute('action'),
				data = new FormData(document.getElementById('contact_form'));

			request.onreadystatechange = function () {
				if (request.readyState === 4 && request.status === 200) {
					var response = JSON.parse(request.responseText);
					if (response.ret_det.success) {
						var input = document.getElementById('contact_form_submit');
						input.parentElement.removeChild(input);
						document.querySelector('#contact_form .response_message').innerHTML = "<span class='success'>" + response.ret_det.message + "</span>";
					} else {
						if (typeof response.data.error_fields !== 'undefined') {
							var errors = [];
							for (var x = 0; x < response.data.error_fields.length; x++) {
								errors.push(response.data.error_fields[x].field_name);
							}
							updateResponseMessage(errors);
						} else {
							document.querySelector('#contact_form .response_message').innerHTML = "<span class='error'>" + response.ret_det.message + "</span>";
						}
					}
					subIP = false;
				}
			}
			request.open(method, url, true);
			request.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
			request.send(data);
		}
		else if (errorsFound.length > 0) {
			updateResponseMessage(errorsFound);
		}
		else {
			document.querySelector('#contact_form .response_message').innerHTML = "<span class='error'>There was a problem processing your request.</span>";
		}
		e.stopImmediatePropagation();
		e.preventDefault();
	});
}

function updateResponseMessage(errors) {
	var response = '';
	if (errors.length == 1) {
		response = "The " + errors[0] + " field is required.";
	} else if (errors.length == 2) {
		response = "The " + errors[0] + " and " + errors[1] + " fields are required.";
	} else {
		var fields = ""
		for (var x = 0; x < errors.length; x++) {
			fields += errors[x];
			if (x < errors.length - 1) {
				fields += ', ';
			}
			if (x == errors.length - 2) {
				fields += 'and ';
			}
		}
		response = "The " + fields + " fields are required.";
	}
	document.querySelector('.response_message').innerHTML = "<span class='error'>" + response + "</span>";
}

document.getElementById('header_mobile_btn').addEventListener('click', function() {
	this.classList.toggle('active');
	document.getElementById('header_nav').classList.toggle('active');
});

var mobileFooter = document.getElementById('mobile_footer');
window.addEventListener('scroll', function() {
	if(window.pageYOffset > 70) {
		mobileFooter.classList.add('active');
	} else {
		mobileFooter.classList.remove('active');
	}
});

if (document.getElementById('rates')) {	
	
	document.getElementById('rates_map_toggle').addEventListener('click', function(e) {
		if (e.target.matches('button')) {
			this.classList.toggle('active');
			document.getElementById('map').classList.toggle('active');
			if (document.querySelector('#rates_caro .dots_container')) {
				document.querySelector('#rates_caro .dots_container').classList.toggle('active');
			}
		}
	});

	if (window.imgs) {
		AutomatitCarousel({
			element: document.getElementById('rates_caro'),
			images: window.imgs,
			imagesAsBackgrounds: true,
			showDots: true
		});
	}

	document.getElementById('rates_sidebar').addEventListener('click', function(e) {
		if (e.target.parentNode.matches('.toggle_content')) {
			if (!e.target.parentNode.matches('.active')) {
				document.querySelector('.toggle_content.active').classList.remove('active');
				e.target.parentNode.classList.add('active');
			}
		}
	});
}

if (document.getElementById('map')) {
	initMap();
}

if (document.getElementById('rates_filter')) {
	var rows = document.querySelectorAll('.rates_row');
	var filters = document.querySelectorAll('#rates_filter ul li');
	var noUnits = document.getElementById('no_units');
	document.querySelector('#rates_filter ul').addEventListener('click', function (e) {
		if (e.target.dataset.size) {
			for (let i = 0; i < filters.length; i++) {
				filters[i].classList.remove('active');
			}
			e.target.classList.add('active')
			let showUnits = 0;
			for (let i = 0; i < rows.length; i++) {
				if (e.target.dataset.size != 'all') {
					rows[i].classList.add('hide');
					if (rows[i].dataset.size == e.target.dataset.size) {
						showUnits++;
						rows[i].classList.remove('hide');
					}
				} else {
					showUnits++;
					rows[i].classList.remove('hide');
				}
			}
			if(!showUnits) {
				noUnits.style.display = 'block';
			} else {
				noUnits.style.display = 'none';
			}
		}
	})
}

function initMap(){
    var lat = document.getElementById('map').getAttribute('data-lat');
    var lng = document.getElementById('map').getAttribute('data-lng');
    var link = document.getElementById('map').getAttribute("data-link");
    var new_tab = document.getElementById('map').getAttribute("data-extflag")
    var pin = new google.maps.LatLng(lat, lng);
    var pin_icon = {
        url: "/static/images/icons/map_pin.svg",
        scaledSize: new google.maps.Size(50, 50)
    }

    var myOptions = {
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        scrollwheel: false
    }
    var map = new google.maps.Map(document.getElementById('map'), myOptions);
    var marker = new google.maps.Marker({
        position: pin,
        icon: pin_icon,
        animation: google.maps.Animation.DROP,
        map: map
    });
    map.setCenter(pin);
    map.setZoom(15);

    google.maps.event.addListener(marker, 'click', function() {
    	// for external google maps link
		    window.open(link, "_blank");		    
	});
}

if (document.getElementById('home_about_right')) {
	let about = document.getElementById('home_about_heading');
	let feats = document.getElementById('home_feats_heading');
	let aboutBox = document.getElementById('home_about_text');
	let featsBox = document.getElementById('home_about_feats');
	document.getElementById('home_about_right').addEventListener('click', function(e) {
		if(e.target.closest('.show_open')) {
			if(e.target.closest('.show_open') == feats) { //clicked on feats
				aboutBox.classList.remove('open');
				featsBox.classList.add('open');
				about.classList.add('show_open');
				feats.classList.remove('show_open');
			} else { // clicked on about
				aboutBox.classList.add('open');
				featsBox.classList.remove('open');
				about.classList.remove('show_open');
				feats.classList.add('show_open');
			}
		}
	})
}

if (document.getElementById('home_reviews_caro')) {
	const reviewsCaro = AutomatitCarousel({
		element: document.getElementById('home_reviews_caro'),
	})

	let reviewLeft = document.getElementById('review_caro_left');
	let reviewRight = document.getElementById('review_caro_right');

	reviewLeft.addEventListener('click', () => {
		reviewsCaro.slideLeft();
	});
	
	reviewRight.addEventListener('click', () => {
		reviewsCaro.slideRight();
	});
}

if (document.getElementById('rates_fac_info_toggle')) {
	document.getElementById('rates_fac_info_toggle').addEventListener('click', function() {
		this.classList.toggle('open');
		document.getElementById('rates_sidebar').classList.toggle('open');
	})
}

$('#about_view_rates img').click(function () {
	$('#map').velocity('scroll', {duration: 1000});
})

if (!Element.prototype.matches)
	Element.prototype.matches = Element.prototype.msMatchesSelector ||
		Element.prototype.webkitMatchesSelector;

if (!Element.prototype.closest) {
	Element.prototype.closest = function (s) {
		var el = this;
		if (!document.documentElement.contains(el)) return null;
		do {
			if (el.matches(s)) return el;
			el = el.parentElement || el.parentNode;
		} while (el !== null && el.nodeType === 1);
		return null;
	};
}